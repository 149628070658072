/**
 * = Typography
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: $headings-font-weight;
}

p,
ol li,
ul li {
	font-family: $font-family-base;
	font-size  : $paragraph-font-size;
	font-weight: $paragraph-font-weight;
	line-height: $paragraph-line-height;
}

a,
.text-action {
	color      : $link-color;
	font-weight: $font-weight-normal;
	transition : $transition-base;

	&:hover {
		color : $link-hover-color;
		cursor: pointer;
	}
}

article {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 2.5rem 0 2rem 0;
	}

	p,
	blockquote {
		font-size    : 1.27rem;
		margin-bottom: 2rem;
	}

	ul li,
	ol li {
		font-size    : 1.27rem;
		margin-bottom: 1.5rem;
	}

	ul,
	ol {
		margin-bottom: 2.5rem;
	}

	a {
		color          : $tertiary;
		text-decoration: underline;

		&:hover {
			color          : $tertiary;
			text-decoration: none;
		}
	}

}

blockquote {
	font-size    : 1.27rem;
	background   : #f5f9fc;
	border-radius: 10px;
	padding      : 25px;
	font-style   : italic;
}

.lead {
	+.btn-wrapper {
		margin-top: 3rem;
	}

	@include media-breakpoint-down(md) {
		font-size: $font-size-base;
	}
}

.text-italic {
	font-style: italic !important;
}

.description {
	font-size: $font-size-sm;
}

// Section titles

.display-1,
.display-2,
.display-3,
.display-4 {
	font-weight: $display-font-weight;
	color      : $headings-color;
}

@include media-breakpoint-down(md) {
	.display-2 {
		font-size: $display3-size;
	}
}

@include media-breakpoint-down(lg) {
	.display-3 {
		font-size: $display4-size;

	}
}

@include media-breakpoint-down(sm) {
	.display-4 {
		font-size: $h4-font-size;
	}
}

// Blockquotes
.blockquote {
	font-family: 'Sanchez', serif;
	font-style : italic;
	font-weight: 400;

	.blockquote-footer {
		font-size  : $font-size-lg;
		font-weight: $font-weight-bolder;
	}
}

// Font Sizes

.font-small {
	@include font-size($font-size-sm);
	font-weight: $font-weight-light;
}

.font-base {
	@include font-size($font-size-base);
	font-weight: $font-weight-light;
}

.font-medium {
	@include font-size($font-size-lg);
	font-weight: $font-weight-light;
}

// Set color of theme buttons
@each $color,
$value in $theme-colors {
	.text-#{$color} {

		&,
		&:hover {
			color: $value !important;
		}

		a:not(.btn),
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		.h1,
		.h2,
		.h3,
		.h4,
		.h5,
		.h6,
		.display-1,
		.display-2,
		.display-3,
		.display-4 {
			color: $value;
		}

		.text-muted {
			color: rgba($value, .7) !important;
		}
	}
}

// Set color of social icons
@each $color,
$value in $brand-colors {
	.text-#{$color} {
		color: $value;
	}
}

.folder-structure li {
	font-size: 1.2rem;
}